import { useCallback, useEffect, useState } from "react";
import Navbar from "../components/header/Navbar";
import Wave from "../components/header/Wave";
import Card from "../components/marriageToolkit/card";
import LatestPost from "../components/marriageToolkit/latestPost";
import Topic from "../components/marriageToolkit/topic";
import Footer from "../components/Footer";
import {get} from '../services/apiHandler'
import { useLocation } from "react-router-dom";
export default function MarriageToolkit() {
  let [data,setData]=useState([])
  const [topic,setTopic]=useState([])
  const {state}=useLocation()

  const [searchResult,setSearchResult]=useState()
  // const [currentPage,setCurrentPage]=useState()
  let [apiData,setapiData]=useState([])
  const[ latestPost,setLatestPost]=useState([])
  useEffect(() => {  window.scrollTo({top: 0, behavior: 'smooth'}); 
 
  }, [searchResult]);
  const handlePageChange=(index)=>{
    const skip=(9*index);
    const operation=[...apiData]
    data=operation.splice(skip,9);
    setData([...data])
    window.scrollTo({top: 0, behavior: 'smooth'});

  }
  function loadData() {
    
   

    get("api/get_marriage_toolkit")
      .then((res) => {
      
      if(res.data.success){
     
      
       
        let post=res.data.data.map((data)=>{
          data.orgDate=data.date;
          data.date= data.date.split("-")
         
           return data
        })
        
        apiData=[...res.data.data]
        setapiData([...apiData])
        setLatestPost(post.splice(0,4))
        if(state?.ind){
          datafilter(state.ind,[...res.data.data])
        }
      
       else{
      
        setData(res.data.data.splice(0,9))}
        get("api/get_topic")
      .then((res) => {
      
      if(res.data.success){
        setTopic(res.data.data.reverse())
        
      }})
     
      
      }  // window.location.reload();
          //  onClose();
        
      })
      .catch((error) => {
        
      });
  }
  useEffect(() => {
    
  
  loadData()

  }, [])
  const datafilter=(filterVal,data)=>{
  
   setSearchResult(filterVal)
 if(data==null)
 data=[...apiData];
    const filteredData=data.filter((item)=>item.content.indexOf(filterVal)!=-1||item.topic.indexOf(filterVal)!=-1||item.title.indexOf(filterVal)!=-1)
  
    setData([...filteredData])

  }

  return (
    <>

      <section>
     
        <Wave title="Marriage Toolkit" subTitle="" current="Marriage Toolkit" />
     
       {searchResult&& <>
      <div className=" w-full  ">
        <div className="w-full   flex justify-between py-5 px-2 sm:p-5 shadow-[rgba(0, 0, 0, 0.24)_0px_3px_8px;] items-center">
          <div className="right w-fit">
          
              {" "}
              <p className=" size-20  font-[600] text-[#641662] text-[18px] md:text-[25px]  pb-1 ">
                {" Search Results For "}{searchResult}
                {/* {props.title} */}
              </p>
           
            <p className="size-20  sm:text-[15px] font-[600] text-[#641662] cursor-pointer text-[12px]  sm:w-fit" onClick={()=>datafilter("",null)}>
              {" "}
              {"Go To Marriage Toolkit"}
            </p>
          </div>
        
        </div>
      </div>
    </>}
        <div className="flex justify-center items-start w-full flex-wrap">
            <div className="w-full md:w-[70%] justify-center flex md:justify-start flex-wrap ">
            <p className="feaure_sub-heading  md:px-5 w-[90%]  font-[400] text-[13px] text-justify text-[#363636] leading-7">Here you will find  carefully crafted articles which can will make you think about some rarely discussed aspects of marriage.  Read up on topics ranging from changing social mores , mental health ,legal aspects in marriage.</p>
              { data.map((data,i)=>{
                return <Card key={i} content={data.content} img={data.image_path} title={data.title} date={data.orgDate} latestPost={latestPost} subHeading={data.topic} url={data.url} topic={topic}/>
              })}
            
       
            </div>
            <div className="w-full flex flex-col sticky top-[150px]  justify-start items-center mt-2 mb-6 md:w-[30%] md:max-w-[350px]    ">
                <LatestPost data={latestPost}  topic={topic} />
                <div className="mt-2">
               {topic.length>0&& <Topic data={topic} datafilter={datafilter} searchResult={searchResult}/>}
                </div>
                </div>
          </div>
          <div className=" flex justify-center my-5">
            <div className="w-[90%] flex">
           <span className="text-[#521350] font-[600] text-[15px]"> page &nbsp;</span>
              <p className="border-[1px] hover:border-[#521350] px-5" onClick={()=>handlePageChange(0)}>1</p>
              <p className="border-[1px] hover:border-[#521350] px-5"  onClick={()=>handlePageChange(1)}>2</p>
            <select name="row" id="row" className="border-[1px] border-[#521350] px-5"  onChange={(e)=>handlePageChange(e.target.value)}>
            <option className="text-[#521350] " value="1">...</option>
            {apiData.slice(2,apiData.length-2).map((_,index)=>{
             return <option className="text-[#521350] " value={index+2}>{index+3}</option>
            })}
            
            
            </select>
            <p className="border-[1px] hover:border-[#521350] px-5" onClick={()=>handlePageChange(apiData.length-2)}>{apiData.length-1}</p>
            <p className="border-[1px] hover:border-[#521350] px-5" onClick={()=>handlePageChange(apiData.length-1)}>{apiData.length}</p>
          </div>
          </div>
      </section>
    </>
  );
}
