import React from "react";
import "../components/Style.css";
import Navbar from "../components/header/Navbar";
import Wave from "../components/header/Wave";

import Footer from "../components/Footer";
import Price from "../components/Pricing/price";
import Content from "../components/services/Content";
import service1 from "../asset/images/home03.jpg";
import service2 from "../asset/images/service2.png";
import service3 from "../asset/images/service3.png";
import service4 from "../asset/images/service4.png";
export default function Services() {
  return (
    <React.Fragment>
      <section>
        <Wave title="Services" subTitle="" current="services" />
        <div className="flex flex-col justify-around mx-[5%]  md:flex-nowrap flex-wrap">
          <p className="feaure_sub-heading font-[400] text-[13px] text-justify text-[#363636] leading-7">
            Knotrite had developed KnotRiteMetric®, a suite of assessment instruments and customized relationship coaching plans focusing on the state of preparedness for relationship/marriage in the Indian societal space. These are one-of-a-kind tools based on proven behavioral, and psychological principles adapted to the Indian traditions and customs.
          </p>
          <Content
            img={service1}
            taketestbutton={true}

            heading="Preliminary Assessment for Relationship Readiness "
            sub_heading={"<span>This free assessment called “KnotRiteReadiness” measures your relationship/ marriage readiness. The report predicts your attitude and behavior in a relationship – how comfortable you are with closenes  s and intimacy, how much independence and autonomy you want within a relationship, the amount of trust you place in others, your emotional resilience, etc. This, in turn, will determine the quality of your relationship in the long run.<br/> This is a methodology developed to capture different ways in which people go about forming intimate connections with others specifically for the relationship and marriage compatibility of couples.  <br/>This is a free test. Go ahead and gauge your marriage readiness with the <strong>KnotRiteReadiness</strong> Score.  <p>"}
          />
          <Content
            img={service2}

            heading="Comprehensive Assessments & Personalized Matches"
            sub_heading={"<p>Discover yourself with KnotRiteType! This in-depth assessment reveals your personality traits, communication style, and conflict resolution skills. Understand your strengths and growth areas to build stronger relationships. Find your ideal partner type and receive matching profiles privately via email—never published on our site. True connections start with self-awareness and confidentiality!.</p>"}
          />
          
          {/* <Content
            img={service3}

            // heading="KnotRiteCoupleMatch Assessment"
            sub_heading={"<p></p>"}
          /> */}
          <Content
            img={service4}
            heading="Customised Coaching Plans"
            sub_heading={"<p>Based on the assessment results, KnotRite provides customized action plans to address specific areas of improvement. These personalized action plans meticulously crafted by seasoned professionals. These are designed to help Individuals and couples navigate challenges and strengthen their bond over time. The content and duration of these plans are tailored to the individual needs as determined by the assessment reports.  <br/>We believe that you will be able to look gain deeper insights into what it takes to make a relationship and marriage happy.Managing expectations are crucial, our plans can help you build a nearly-perfect life.<br/><br/>This is part of our premium services. Please sign up to access these services</p>"}


  

        />
      </div>
    </section>
    </React.Fragment >
  );
}
