import React, { useEffect, useState } from "react";
import "../components/Style.css";
import dwdIcon from '../asset/images/dowload-icon.png'
import Wave from "../components/header/Wave";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import Card from "../components/myAccount/Cards";
import AddPartner from "../components/modal/AddPartner";
import { get, post, remove } from "../services/apiHandler";
import TestModal from "../components/modal/TestModal";
import TestPriceModal from "../components/modal/TestPriceModal";
import { Dna } from "react-loader-spinner";
import PenndingInvitations from "../components/modal/penndingInvitations";
import girlWithHeart from '../asset/images/myAccount/01Header.png'
import VerifyOTPModal from "../components/modal/VerifyOTPModal";
import Knotritemeter from '../asset/images/myAccount/02Knotritemeter.png'
import MirrorLady from '../asset/images/myAccount/04Mirror.png'
import Couple from '../asset/images/myAccount/05Couple.png'
import CoupleMatch from '../asset/images/myAccount/07Couplematch.png'
import downloadIcon from '../asset/images/myAccount/downloadIcon.png'
import refershIcon from '../asset/images/myAccount/03Refresh_icon.png'
import ToggleButton from "../components/common/toggleButton";
export default function MyAccount() {
  const Nav = useNavigate();
  const [pit, setpt] = useState(false)
  let [user, setUser] = useState({ gender: "male" });

  const [partners, setPartners] = useState([]);
  const [loader, setloader] = useState(0);
  const [isRrport, setis_Report] = useState(false)
  // const[name,setName]=useState("")
  const [visible, setVisible] = useState(false);
  const [Invisible, setInVisible] = useState(false);
  const [PaidButtonStatue, setPaidButtonStatue] = useState("Resume TEST")
  const [testvisible, setTestVisible] = useState(false);
  const [Pricingtestvisible, setPricingTestVisible] = useState(false);
  const [deleteModal, setDeleteModal] = useState({ visible: false, id: null })
  const [deleteModalConf, setDeleteModalConf] = useState({ visible: false, id: null })
  const [showModalOTP, setShowModalOTP] = useState(false);
  const [otpStatus, setOtpStatus] = useState(false);
  const [readMore, setReadMore] = useState({ free: false, paid: false, couple: false })

  const [msg, setMsg] = useState("")
  const { state } = useLocation()


  const onClose = () => {
    setInVisible(false)
    setVisible(false);
  }
  const onTestClose = () => setTestVisible(false);
  const onPricingTestClose = () => setPricingTestVisible(false);
  
  const handleChangeUserAccess=async(is_public)=>{
   console.log(is_public);
   
    setUser({...user,is_public})
    await post(`api/change-profile-accessbility/`, {is_public})
    
  }
  const handleInput = (event) => {
    if (event.target.name == 'name' && event.target.value.length > 23) {
      return
    }

    if (event.target.name == "dob") {
      let date = new Date(event.target.value);

      let pastYear = new Date().getFullYear() - event.target.value
      user.dob = `${pastYear}-01-01`;
      user.age = event.target.value;

      console.log(user);

      // user.age = event.target.value;
    } else {
      user[event.target.name] = event.target.value;
    }
    setUser({ ...user });
    post(`api/update_user/`, user).then((res) => {
      if (res.data.success) {

      }
    });
  };
  const handleDeleteModal = (id) => {
    setDeleteModal({ visible: !deleteModal.visible, id: id })
  }
  const handleDeleteModalConf = (id) => {
    setDeleteModalConf({ visible: !deleteModalConf.visible, id: id })
  }
  async function loadData() {
    setloader(false)
    setPartners([])
    get("api/partners").then((res) => {

      if (res.data.success) {
        setPartners([...res.data.data]);

        setloader(true)



      }

      get("api/user")
        .then((res) => {
          if (res.data.success) {
            setUser({
              ...res.data.data,
              gender: res.data.data.gender != null ? res.data.data.gender : "male",
              is_verified: res.data.data.is_verified,

              age: new Date().getFullYear() - res.data.data.dob?.split("-")[0],
            });
          }
          else {
            localStorage.removeItem("login");
            Nav("/", { state: { msg: "Please Login To Access My Account Page", redirect: true } })
          }

          get("api/paid_assessment_exists")
            .then((res) => {
              if (res.data.success) {

                if (res.data.data.is_completed == 0) {
                  setPaidButtonStatue("Resume TEST")

                }
              }
              else {
                setPaidButtonStatue("Take Test")
              }
            })

        })


      document.getElementById("Reload")?.classList.remove("animate-spin")
    }).catch((error) => {

    });
  }
  const ResumeTest = () => {

    Nav("/individualpaidtest")
  }
  useEffect(() => {
    loadData();

    if (state) {
      setMsg(state.msg)
    }
  }, []);
  // const getSubscriptionName = () => {
  //   if (subscription.id == 6) return "KnotRite Premium ";
  //   if (subscription.id == 7) return "KnotRiteCoupleMatch";
  //   if (subscription.id == 8) return "KnotRite Counselling Per Hour ";
  //   if (subscription.id == 4) return "KnotRite Standard";
  // };

  const deletePartner = (id) => {
    remove(`api/partners/${id}`).then((res) => {
      if (res.data.success) {
        loadData();
        handleDeleteModal(null)
      }
    });
  };
  const verifyAccount = async () => {
    console.log(user.dob);
    if (user.mobile_number.length != 10) {
      setMsg("Please enter valid mobile number")
      return
    }
    if (user.dob != "" && user.mobile_number && user.mobile_number.length == 10) {
      const response = await post(`api/check-number-valid/`, user)
      console.log(response);
      if (response.data.success) {
        setOtpStatus(true);
        setShowModalOTP(true);
      }
      else {
        setMsg(response.data.data)
      }
    }

  }
  return (

    <React.Fragment>
      {
        !user.email ?
          // <div className="min-h-[100vh] flex justify-center items-center ">
          //   <Dna
          //     visible={true}
          //     height="80"
          //     width="80"
          //     ariaLabel="dna-loading"
          //     wrapperStyle={{}}
          //     wrapperClass="dna-wrapper"
          //   /></div>

          <section>
            <Wave title="My Love Ledger " subTitle="" current="My Love Ledger " />
            <div className="w-full flex flex-col justify-center items-center  ">
              <div className="w-[90%] flex justify-center md:w-[50%]  bg-[#f0daf4d0] p-8">
                <div role="status" class="w-[80%] p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700">
                  <div class="flex w-full items-center justify-between">
                    <div className="w-full">
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[50%] mb-2.5"></div>
                      <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    </div>
                    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                  </div>
                  <div class="flex w-full items-center pt-4 justify-between">
                    <div className="w-full">
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[50%] mb-2.5"></div>
                      <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    </div>
                    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                  </div>
                  <div class="flex w-full items-center pt-4 justify-between">
                    <div className="w-full">
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[50%] mb-2.5"></div>
                      <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    </div>
                    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                  </div><div class="flex w-full items-center pt-4 justify-between">
                    <div className="w-full">
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[50%] mb-2.5"></div>
                      <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    </div>
                    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                  </div><div class="flex w-full items-center pt-4 justify-between">
                    <div className="w-full">
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[50%] mb-2.5"></div>
                      <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    </div>
                    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                  </div><div class="flex w-full items-center pt-4 justify-between">
                    <div className="w-full">
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[50%] mb-2.5"></div>
                      <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    </div>
                    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                  </div><div class="flex w-full items-center pt-4 justify-between">
                    <div className="w-full">
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[50%] mb-2.5"></div>
                      <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    </div>
                    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                  </div><div class="flex w-full items-center pt-4 justify-between">
                    <div className="w-full">
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[50%] mb-2.5"></div>
                      <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    </div>
                    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                  </div><div class="flex w-full items-center pt-4 justify-between">
                    <div className="w-full">
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[50%] mb-2.5"></div>
                      <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    </div>
                    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                  </div><div class="flex w-full items-center pt-4 justify-between">
                    <div className="w-full">
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[50%] mb-2.5"></div>
                      <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    </div>
                    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                  </div>


                </div>
              </div>

            </div>
          </section>

          : <>
            <section>
              <Wave title="My Account" subTitle="" current="My Account" image={girlWithHeart} />
              <div className="w-full flex relative flex-col items-center  ">
                {msg && <div className="fixed z-20 md:top-[20%]  top-[10%] right-4  rounded-lg bg-red-400 text-white">
                  <div className="relative w-full p-5">
                    <div className="p-2 absolute right-1 top-[-5%] cursor-pointer" onClick={() => { setMsg("") }}>X</div>
                    {msg}
                  </div>
                </div>}
                <div className="w-[93%] md:w-[60%]  bg-[#f0daf4d0] py-8 px-3 md:p-8">
                  <h1 className="font-[700] md:text-[21px] text-[#613359] text-[18px]">
                    PERSONAL PROFILE
                    {/* font-[500] text-[13px] text-[#363636]LE */}
                    <p className=" text-[15px] font-[500] mt-2  font-[500] text-[16px] text-[#613359] ">Fill in your complete details and verify phone to proceed further</p>
                  </h1>

                  <div className="relative flex flex-row justify-between flex-wrap">
                    <div className="md:mb-3 md:mt-7 mt-3 md:w-[50%] w-[100%] ">
                      <label
                        for="email2"
                        className="text-sm text-navy-700  font-[600] text-[#333333]"
                      >
                        Full name <sup className="text-red-500 mt-2 text-[15px]">*</sup>
                      </label>
                      <div className="flex ">
                        <input
                          type="text"
                          id="email2"
                          placeholder="Enter your full name"
                          name="name"
                          value={user.name}
                          disabled
                          // onChange={handleInput}
                          className="mt-2 flex md:h-12 h-[40px] placeholder:text-[12px] mr-2  font-[600] items-center justify-center rounded-md border bg-white/0 p-3 md:w-[90%] w-full  outline-none border-[#AC4884] text-[#AC4884]   bg-slate-50"
                        />

                      </div>
                    </div>
                    <div className="flex md:w-[50%] w-[100%] justify-between">
                      <div className="md:mb-3 md:mt-7 mt-3 md:w-[45%] w-[40%]">
                        <label
                          for="email2"
                          className="text-sm text-navy-700  font-[600] text-[#333333]"
                        >
                          Age <sup className="text-red-500 mt-2 text-[15px]">*</sup>
                        </label>
                        <input
                          type="text"
                          id="email2"
                          placeholder="Two digits"
                          name="dob"
                          disabled={user.is_verified}
                          value={user.age ? user.age : ""}
                          onChange={handleInput}

                          className="mt-2 placeholder:text-[12px] flex md:h-12 h-[40px]  font-[600] items-center justify-center rounded-md border bg-white/0 p-3 w-[100%] text-sm outline-none border-[#AC4884] text-[#AC4884]   bg-slate-50"
                        />
                        {/* <input
                          type="date"
                          id="dobuser"
                          placeholder=" input"
                          name="dob"
                          // disabled={user.is_verified} 
                          value={user.dob}
                          onChange={handleInput}
                          className="mt-2 hidden  md:h-12 h-[40px]  font-[600] items-center justify-center rounded-md border bg-white/0 p-3 w-[100%] text-sm outline-none border-[#AC4884] text-[#AC4884]   bg-slate-50"
                        /> */}
                      </div>
                      <div className="md:mb-3 md:mt-7 mt-3 md:w-[45%] w-[40%]">
                        <label
                          for="email2"
                          className="text-sm text-navy-700  font-[600] text-[#333333]"
                        >
                          Sex <sup className="text-red-500 mt-2 text-[15px]">*</sup>
                        </label>
                        {/* <input
                          type="text"
                          id="email2"
                          disabled={user.is_verified}
                          placeholder=" input"
                          name="gender"
                          value={user.gender}
                          onChange={handleInput}
                          className="mt-2 flex md:h-12 h-[40px]  font-[600] items-center justify-center rounded-md border bg-white/0 p-3 w-[100%] text-sm outline-none border-[#AC4884] text-[#AC4884]   bg-slate-50"
                        /> */}
                        <select name="gender"
                          value={user.gender}
                          onChange={handleInput}
                          disabled={user.is_verified}
                          className="mt-2 flex md:h-12 h-[40px] placeholder:text-[12px]  font-[600] items-center justify-center rounded-md border bg-white/0 p-3 w-[100%] text-sm outline-none border-[#AC4884] text-[#AC4884]   bg-slate-50">
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="relative flex flex-row justify-between w-full md:flex-nowrap flex-wrap">
                    <div className="md:mb-3 relative flex items-center md:mt-7 mt-3 md:w-[50%] w-[100%]">
                      <div className={`max-w-full ${!user.is_verified ? "md:min-w-[70%] w-full" : "min-w-full"}`}>
                        <label
                          for="email2"
                          className="text-sm text-navy-700  font-[600] text-[#333333]"
                        >
                          Phone <sup className="text-red-500 mt-2 text-[15px]">*</sup>
                        </label>
                        <input
                          type="text"
                          id="email2"
                          placeholder="Enter 10 digit number"
                          name="mobile_number"
                          disabled={user.is_verified}
                          value={user.mobile_number}
                          onChange={handleInput}
                          className="mt-2 flex md:h-12 h-[40px] placeholder:text-[12px] font-[600] items-center justify-center rounded-md border bg-white/0 p-3 md:w-[90%] w-[100%] text-sm outline-none border-[#AC4884] text-[#AC4884]   bg-slate-50"
                        />
                      </div>
                      {!user.is_verified && <div className="min-w-[20%] absolute md:right-10 right-3 top-1 md:top-2 mt-8">
                        <button
                          onClick={verifyAccount}
                          className="   bg-[#67365E] font-[600]  w-full text-white md:text-[13px] text-[12px]  flex justify-around items-center  h-[30px] rounded-full px-5  whitespace-nowrap   hover:bg-pink-800 duration-500 cursor-pointer"
                        >
                          GET OTP
                        </button>
                      </div>}
                    </div>
                    <div className="md:mb-3 md:mt-7 mt-3 md:w-[50%] w-[100%] ">
                      <label
                        for="email2"
                        className="text-sm text-navy-700  font-[600] text-[#333333]"
                      >
                        Email Address <sup className="text-red-500 mt-2 text-[15px]">*</sup>
                      </label>
                      <input
                        type="text"
                        id="email2"
                        placeholder=" input"
                        name="email"
                        disabled={true}
                        value={user.email}
                        onChange={handleInput}
                        className="mt-2 flex md:h-12 h-[40px] placeholder:text-[12px]  font-[600] items-center justify-center rounded-md border bg-white/0 p-3 w-[100%] text-sm outline-none border-[#AC4884] text-[#AC4884]   bg-slate-50"
                      />
                    </div>
                  </div>

                  <div className="rounded-lg mt-3 bg-[#98597f7b] py-3 px-6 shadow-xl">
                    {/* <h2 className="font-[600] text-[15px] text-black ">Are you sure you want to do that?</h2> */}
                        <div className="flex justify-between md:flex-row flex-col gap-y-2 gap-x-5">
                    <p className="mt-2 text-[15px] font-[500] text-black">
                    Enable profile  sharing and receiving  (without phone no.) of other prospective matches?
                   
                    </p>

                    {/* <div className="mt-4 flex gap-2">
                      <button onClick={()=>handleChangeUserAccess(true)} type="button" className={`rounded h-fit px-4 py-2 text-sm font-medium  ${user.is_public?'text-white bg-[#a536919c]':'bg-gray-50 text-gray-600'}`}>
                        Yes, I'm sure
                      </button>

                      <button onClick={()=>handleChangeUserAccess(false)} type="button" className={`rounded h-fit px-4 py-2 text-sm font-medium  ${!user.is_public?'text-white bg-[#a536919c]':'bg-gray-50 text-gray-600'}`}>
                        No, Don't Share
                      </button>
                    </div> */}
                    {user&&<ToggleButton button={user&&user.is_public} onActive={()=>handleChangeUserAccess(true)} onDeActive={()=>handleChangeUserAccess(false)} />}
                    </div>
                    <p className="mt-2 text-[15px] font-[500]"><i>Your privacy matters: Profiles are emailed privately, never
                    published on the website.</i></p>
                  </div>

                  {!user.is_verified ? <></> : <button
                    onClick={() => Nav("/pricing")}
                    className="  md:w-[33%] mt-5 bg-[#67365E] font-[600] w-full text-white text-[13px]  flex justify-around items-center py-[5px] px-2 rounded-3xl  hover:bg-pink-800 duration-500 cursor-pointer"
                  >
                    EXPLORE PLANS
                  </button>}
                  <h1 className="font-[700] text-[#613359]  text-[18px] md:text-[21px] mt-7">
                    MY KNOTRITE JOURNEY

                  </h1>
                  <p className=" text-[15px] font-[500]my-2  text-[#613359]">Unlock deeper connections with insightful self and couple
compatibility reports—rooted in science, tailored to reality.
Discover yourself and find matches beyond the surface.</p>
                  {/* <img src={Knotritemeter} className="mx-auto" alt=""  /> */}
                  <div className="flex justify-between mt-2 items-center">
                    <h1 className="font-[700] uppercase md:text-[18px] text-[16px] my-4 text-[#613359]">
                      Self Assessments
                    </h1>
                    <img src={MirrorLady} className="w-[75px] md:mr-3 " alt="" />
                  </div>

                  {/* font-[500] text-[13px] text-[#363636] */}
                  <p className=" text-[15px] font-[500] my-2  text-[#613359]">Embrace the journey of self-discovery  and transform your approach to relationships. Your path to deeper self-awareness and healthier connections starts here.We have these two Self evaluations for you. </p>

                  <div className={`${readMore.free ? "block" : "flex"} my-2  w-full justify-between items-center`}>
                    <div className={`pl-0 text-[#613359] pr-5 `}> <p className=" text-[17px] font-[600]">KnotRiteReadiness</p>{readMore.free && "Childhood  relationships shape our ability to connect with others as adults. Recognizing these patterns is crucial to understand your marriage readiness.Start your journey with this free test. "}</div>
                    <p onClick={() => setReadMore({ ...readMore, free: !readMore.free })} className="whitespace-nowrap text-[#613359] font-bold text-[13px] cursor-pointer">{!readMore.free ? "...Read more" : "Read Less"}</p>
                  </div>

                  <div className={`${readMore.paid ? "block" : "flex"} my-2 w-full justify-between items-center`}>
                    <div className={`pl-0 text-[#613359] pr-5 `}> <p className=" text-[17px] font-[600]">KnotRiteType</p>{readMore.paid && "Explore your personality like never before! Understand your communication style, conflict resolution skills, and ideal partner traits. Get insights into compatibility and discover matches that truly align with you"}</div>
                    <p onClick={() => setReadMore({ ...readMore, paid: !readMore.paid })} className={`whitespace-nowrap text-[#613359] font-bold text-[13px] cursor-pointer ${!readMore.paid && "ml-[2.98rem]"} cursor-pointer`}>{!readMore.paid ? "...Read more" : "Read Less"}</p>
                  </div>

                  <div className="flex justify-between mt-5 items-center flex-wrap md:flex-nowrap mb-[20px] p-5 rounded-2xl  bg-[#98597f7b]">
                    <div className="md:w-[50%] w-[100%] mb-2">
                      <h1 className="font-[600] text-[15px]  ">
                        My KnotRiteReadiness
                      </h1>
                      {/* font-[500] text-[13px] text-[#363636] */}
                      <p className=" text-[15px] font-[500] mt-2  ">
                        (Free test for marriage readiness)
                      </p>
                    </div>
                    <div className="md:w-[50%] w-[100%] flex  justify-around items-center ">
                      {/* <button
                  className="md:w-[30%]  bg-[#AC4884] font-[600] w-[40%] text-white text-[10px]  flex justify-center items-center py-[5px] md:px-6 px-2 rounded-3xl  hover:bg-pink-800 duration-500 cursor-pointer"
                  onClick={() => Nav("/free-test")}
                >
                  PAUSE
                </button> */}
                      {user.reports &&
                        user.reports?.filter((data) => data.question_category == 4)
                          .length > 0 ? (
                        <div className="flex justify-around w-[90%]">
                          <a target="_blank" className="md:w-[45%] py-[5px] px-2 text-center  bg-[#67365E] font-[600] text-white text-[10px]  flex justify-center items-center rounded-3xl  hover:bg-pink-800 duration-500 cursor-pointer  w-[45%]" >

                            Score:<br />  {user.reports.filter((data) => data.question_category == 4)[0].report_data?.score}

                          </a>
                          <a target="_blank" className="md:w-[40%] py-[5px] px-2  bg-[#67365E] font-[600] text-white text-[10px]  flex justify-center items-center rounded-3xl  hover:bg-pink-800 duration-500 cursor-pointer  w-[45%]" href={window.screen.width < 768 ? "https://knotrite.in/backend/" + user.reports.filter((data) => data.question_category == 4)[0].mobile_file_path : "https://knotrite.in/backend/" + user.reports.filter((data) => data.question_category == 4)[0].file_path} >

                            {/* <span className=" text-[20px] p-0">↓</span> */}
                            Report &nbsp; <img src={downloadIcon} className="w-[20px]" alt="" />

                          </a>
                          {/* <a className="cursor-pointer" onClick={()=>saveFile("https://knotrite.in/backend/" + user.reports.filter((data) => data.question_category == 4)[0].file_path)}  > <img src={dwdIcon} className="ml-2 w-[35px] h-[30px]"/></a>  */}
                        </div>

                      ) : (
                        <div className="flex justify-around w-[90%]">
                          <button

                            className="md:w-[40%]  bg-[#AC4884] font-[600] w-[45%] text-white text-[10px]  flex justify-center items-center py-[5px] md:px-6 px-2 rounded-3xl  hover:bg-pink-800 duration-500 cursor-pointer"
                            onClick={() => Nav("/free-test")}
                          >
                            Take <br /> Test
                          </button>
                          <a target="_blank" disabled className="md:w-[40%] py-[5px] px-2  bg-[#67365E] font-[600] text-[rgb(186,114,172)] text-[10px]  flex justify-center items-center rounded-3xl   duration-500 cursor-pointer  w-[45%]" >

                            {/* <span className=" text-[20px] p-0">↓</span> &nbsp;Report */}
                            Report &nbsp; <img src={downloadIcon} className="w-[20px]" alt="" />

                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-between items-center flex-wrap md:flex-nowrap mb-[20px] p-5 rounded-2xl  bg-[#98597f7b]">
                    <div className="md:w-[50%] w-[100%] mb-2">
                      {/* font-[500] text-[13px]" text-[#363636] */}
                      <h1 className=
                        " font-[600] text-[15px]  ">My KnotRiteType</h1>
                      <p className=" text-[15px] font-[500] mt-2  ">
                        (Detailed Individual assesment)
                      </p>
                    </div>
                    <div className="md:w-[50%] w-[100%] flex  justify-around items-center ">
                      {/* <button
                  className="md:w-[30%]  bg-[#AC4884] font-[600] w-[40%] text-white text-[10px]  flex justify-center items-center py-[5px] md:px-6 px-2 rounded-3xl  hover:bg-pink-800 duration-500 cursor-pointer"
                  onClick={() => Nav("/free-test")}
                >
                  Resume
                </button> */}
                      {user.reports &&
                        user.reports?.filter((data) => data.question_category == 5 && data.report_data != null)
                          .length > 0 ? (

                        <div className="flex justify-around md:w-[90%]">
                          <a target="_blank" className="md:w-[40%] py-[5px] px-2  bg-[#67365E] font-[600] text-white text-[10px] text-center flex justify-around items-center rounded-3xl  hover:bg-pink-800 duration-500 cursor-pointer  w-[48%]" >

                            Score: <br />  {user.reports.filter((data) => data.question_category == 5)[0].report_data?.score}

                          </a>
                          <a target="_blank" className="md:w-[40%] py-[5px] px-2  bg-[#67365E] font-[600] text-white text-[10px]  flex justify-center items-center rounded-3xl  hover:bg-pink-800 duration-500 cursor-pointer  w-[48%]" href={window.screen.width < 768 ? "https://knotrite.in/backend/" + user.reports.filter((data) => data.question_category == 5)[0].mobile_file_path : "https://knotrite.in/backend/" + user.reports.filter((data) => data.question_category == 5)[0].file_path} >

                            {/* <span className=" text-[20px] p-0">↓</span> &nbsp;Report */}
                            Report &nbsp; <img src={downloadIcon} className="w-[20px]" alt="" />

                          </a>
                          {/* <a className="cursor-pointer" download onClick={()=>saveFile("https://knotrite.in/backend/" + user.reports.filter((data) => data.question_category == 5)[0].file_path )}> <img src={dwdIcon} className="ml-2 w-[35px] h-[30px]"/></a>  */}
                        </div>

                      ) : (
                        user.reports?.filter((data) => data.question_category == 5 && data.is_completed == 0)
                          .length > 0 ?
                          <div className="flex justify-around w-[90%]">
                            <button
                              className="md:w-[40%]  bg-[#AC4884] font-[600] w-[48%] text-white text-[10px]  flex justify-center items-center py-[5px] md:px-6 px-1 rounded-3xl  hover:bg-pink-800 duration-500 cursor-pointer"
                              onClick={() => { PaidButtonStatue == "Resume TEST" && ResumeTest() }}
                            >
                              {PaidButtonStatue == "Take Test" ? <p>Take <br /> Test</p> : PaidButtonStatue}
                            </button>
                            <button
                              className="md:w-[40%]  bg-[#AC4884] font-[600] w-[48%] text-[rgb(186,114,172)] text-[10px]  flex justify-center items-center py-[5px] md:px-6 px-1 rounded-3xl  duration-500 cursor-pointer"
                              onClick={() => Nav("/individualpaidtest")}
                            >
                              {/* <span className=" text-[20px] p-0">↓</span> &nbsp;Report */}
                              Report &nbsp; <img src={downloadIcon} className="w-[20px]" alt="" />
                            </button>
                          </div> :

                          user && user.paid_assessment_remaining > 0 ? <div className="flex justify-around w-[90%]">

                            <button
                              className="md:w-[40%]  bg-[#AC4884] hover:bg-pink-800 duration-500 font-[600] w-[48%] text-white text-[10px]  flex justify-center items-center py-[5px] md:px-6 px-2 rounded-3xl   cursor-pointer"
                              onClick={() => Nav("/individualpaidtest")}
                              data-te-toggle="tooltip"
                              title={"Give The Paid Test"}
                            >
                              Take <br /> Test
                            </button>
                            <button
                              className="md:w-[40%]  bg-[#67365E] font-[600] w-[48%] text-[rgb(186,114,172)] text-[10px]  flex justify-center items-center py-[5px] md:px-6 px-2 rounded-3xl   duration-500 cursor-pointer"
                              disabled
                            >
                              {/* <span className=" text-[20px] p-0">↓</span> &nbsp;Report */}
                              Report &nbsp; <img src={downloadIcon} className="w-[20px]" alt="" />
                            </button>
                          </div> :
                            <div className="flex justify-around w-[90%]">

                              <button
                                className="md:w-[40%]  bg-[#AC4884] font-[600] w-[48%] text-white text-[10px]  flex justify-center items-center py-[5px] md:px-6 px-2 rounded-3xl  hover:bg-pink-800 duration-500 cursor-pointer"
                                data-te-toggle="tooltip"
                                title={"This is a paid test. Please subscribe in Pricing page"}
                                onClick={() => Nav("/pricing")}
                              >
                                Take <br /> Test

                              </button>


                              <button
                                className="md:w-[40%]  bg-[#67365E] font-[600] w-[48%] text-[rgb(186,114,172)] text-[10px]  flex justify-center items-center py-[5px] md:px-6 px-2 rounded-3xl   duration-500 cursor-pointer"
                                disabled
                              >
                                {/* <span className=" text-[20px] p-0">↓</span> &nbsp;Report */}
                                Report &nbsp; <img src={downloadIcon} className="w-[20px]" alt="" />
                              </button>
                            </div>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-between py-5 items-center">
                    <h1 className="font-[700] uppercase md:text-[18px] text-[#67365E] text-[16px]  mt-7 ">
                      Couple  Assesments
                    </h1>
                    <img src={Couple} className="w-[55px]" alt="" />
                  </div>

                  {/* font-[500] text-[13px] */}
                  <h1 className=" text-left my-2  text-[#613359]">
                    <div className={`my-2  w-full `}>
                      <div className={`pl-0 text-[#613359] pr-5 `}> <p className=" text-[15px] font-[500]">Here comes the most interesting test of all- couple compatibility check! Based on proven scientific principles ,our algorithm checks a prospective partner for quality of match.  Match Quality ranges from <strong>IDEAL</strong> (best) to <strong>POTENTIAL</strong> (needs changes). Go Ahead and invite Your Partner Now!</p>
                        {readMore.couple && <div>
                          <p className=" text-[15px] font-[500] mt-3"><strong>Reports:</strong> &nbsp;You can view the couple match reports when the invited partner accepts your invitation and also completes the individual assessments as you have done. Further, for privacy reasons, the individual assessments of either of you will not be visible to each other on this platform unless you choose share directly.</p>
                          <br />
                          <p><strong>Payments:</strong>&nbsp; You can choose to pay for your partner assessments yourself through various plans or he/she can pay on their own. However for Couple Match assessment , credit balance will be deducted from the inviting partner only once the invited partner accepts.</p>
                        </div>}</div>
                      <p onClick={() => setReadMore({ ...readMore, couple: !readMore.couple })} className="whitespace-nowrap text-[#613359] font-[600] cursor-pointer  text-[14px] mt-2">{!readMore.couple ? "...Read more" : "Read Less"}</p>
                    </div>
                  </h1>
                  <div className="flex w-full justify-around">
                  </div>
                  <div className="acctable">
                    <div className="flex flex-col justify-between items-baseline w-[100%] md:flex-nowrap flex-wrap">
                      <h1 className="font-[600]  text-[15px]  mt-7 mb-7">
                        My Prospective Partners
                      </h1>

                      <div className="flex md:w-[45%] w-[100%] justify-between items-center">
                        <button
                          onClick={() => {
                            if (partners.length >= 3) {
                              handleDeleteModalConf(true)
                            }
                            else
                              setVisible(true)
                          }}
                          className="  md:w-[43%] shadow-lg bg-[#67365E] hover:bg-pink-800   font-[500] text-[#ffffff] text-[11px]  md:text-[13px]  flex justify-around items-center w-[45%] py-[5px] px-2 rounded-3xl  hover:font-[600] duration-500 cursor-pointer"

                        >
                          + Add Partner
                        </button>
                        <button
                          onClick={() => setInVisible(true)}
                          className={` ${pit && "ch-txt"} md:w-[53%] md:my-0 my-3 relative shadow-lg bg-[#67365E] hover:bg-pink-800   font-[500] w-[50%] text-[#ffffff] text-[11px]   md:text-[13px]  flex justify-around items-center py-[5px] px-2 rounded-3xl  hover:font-[600] duration-500 cursor-pointer`}

                        >Pending Invitations

                        </button>
                      </div>
                      {/* font-[500] mt-4 text-[13px]  */}
                      <p className=" text-[15px] font-[500] text-justify mb-2 text-[#613359] mt-2">If you have sent Invitation to a partner, please contact partner to accept. You can refresh this page to check acceptance. and any further  pending invitations . In case you have an invitation, the button will flash. On acceptance your partner details will appear below.
                        <br />
                        {/* <p className=" text-[15px] font-[500]font-[600] mt-4 text-[13px]  text-justify mb-2 ">Refresh Page

                        <svg xmlns="http://www.w3.org/2000/svg" onClick={async (e) => {
                          document.getElementById("Reload").classList.add("animate-spin")
                          await loadData()


                        }} className="inline max-w-[20px] cursor-pointer ml-8" viewBox="0 0 24 24" id="Reload"><path d="M19.91,15.51H15.38a1,1,0,0,0,0,2h2.4A8,8,0,0,1,4,12a1,1,0,0,0-2,0,10,10,0,0,0,16.88,7.23V21a1,1,0,0,0,2,0V16.5A1,1,0,0,0,19.91,15.51ZM12,2A10,10,0,0,0,5.12,4.77V3a1,1,0,0,0-2,0V7.5a1,1,0,0,0,1,1h4.5a1,1,0,0,0,0-2H6.22A8,8,0,0,1,20,12a1,1,0,0,0,2,0A10,10,0,0,0,12,2Z" fill="#580e5c" class="color000000 svgShape"></path></svg>

                      </p> */}

                        <img src={refershIcon} onClick={async (e) => {

                          await loadData()


                        }} className="mx-auto cursor-pointer w-[80px] mt-5" alt="" />

                      </p>
                    </div>


                    <div className="flex  w-full md:mx-4 my-10  justify-items-center justify-center flex-wrap">
                      {partners && partners.length > 0 ?
                        partners.map((data, i) => {

                          const val = data.users_partners ? data.users_partners[0] : data.users_invited_partners[0]
                          return (
                            <Card
                              name={val?.name}
                              mobile={val?.mobile_number}
                              key={i}
                              downloadIcon={downloadIcon}
                              disableReport={() => setis_Report(true)}
                              file_path={data.couple_report?.file_path}
                              mobile_file_path={data.couple_report?.mobile_file_path}
                              score={data.couple_report?.jung_match}
                              id={val?.id}
                              delete={() => handleDeleteModal(data.id)}
                            />
                          );
                        }) :
                        <Card
                          name={""}
                          mobile={""}
                          downloadIcon={downloadIcon}
                        // disableReport={() => setis_Report(true)}
                        // file_path={data.couple_report?.file_path}
                        // mobile_file_path={data.couple_report?.mobile_file_path}
                        // score={data.couple_report?.jung_match}
                        // id={val?.id}
                        // delete={() => (data.id)}
                        />
                      }

                    </div>

                    <div className="acctable">
                      <div className="flex flex-col justify-between items-baseline ">
                        <h1 className="font-[700] text-[21px]  mb-2">
                          PLANS SUBSCRIBED
                        </h1>

                      </div>

                      {user && (user?.plans?.length > 0) ? (
                        <>
                          <div>
                            <div className="flex justify-center">
                              <div className="flex md:w-[70%] w-full my-5 justify-center items-center md:flex-nowrap flex-wrap  border-[#8a4971]  border-solid border-[2px] rounded-xl  ">

                                <table className="w-full ">
                                  {user && user?.plans?.reverse().map((data, i) => {
                                    return <tr key={i} className="  border-r-[#8a4971] ">
                                      <th className={`font-[600] p-2 border-[#8a4971] border-r-2  w-[70%]  mt-[20px] text-[#AC4884] text-[11px] md:text-[13px] ${i < user.plans.length - 1 ? "border-b-2" : ""}`}>
                                        {data && data.plan_data.name.split("(")[0]} <br />
                                        {data.plan_data.name.split("(")[1] && "(" + data.plan_data.name.split("(")[1]}
                                      </th>


                                      {/* <th className=" w-[40%] border-[#8a4971] border-r-2 border-b-2">
                                        <div className="flex  justify-center items-center pt-2 pb-2 pr-2 w-[100%]">
                                          <button

                                            className="  md:w-[50%] font-[600] w-full text-[#AC4884] md:text-[13px] text-[12px]  flex justify-around items-center py-[5px] px-2 rounded-3xl  duration-500 cursor-pointer"
                                          >
                                            {data.end_date}
                                          </button>





                                        </div>
                                      </th> */}
                                      <th className={`md:w-[30%] border-[#8a4971]  ${i < user.plans.length - 1 ? "border-b-2" : ""}`}>
                                        <div className="flex justify-center items-center pt-1 pb-1 pr-2 w-[100%]">
                                          <a href={"https://knotrite.in/backend/" + data.transaction_data[0]?.invoice_data[0]?.file}>  <button

                                            className="  md:w-[100%] font-[600] w-full text-[#AC4884] text-[11px] md:text-[13px]  flex justify-around items-center py-[5px] rounded-3xl  duration-500 cursor-pointer"
                                          >
                                            Invoice &nbsp; <img src={dwdIcon} className="max-w-[20px]" alt="" />
                                          </button>

                                          </a>



                                        </div>
                                      </th>
                                    </tr>



                                  })}
                                </table>

                              </div>
                            </div>
                          </div>

                        </>
                      ) : (

                        <div className="flex w-[100%] justify-between items-center md:flex-nowrap flex-wrap px-4 border-[#8a4971]  border-solid border-[1px] rounded-xl  ">
                          <th className="font-[700] text-[#AC4884] text-[15px] w-[100%]">
                            <div className="flex  justify-center items-center pt-2 pb-2 pr-2 w-[100%]">
                              <button
                                onClick={() => Nav("/pricing")}
                                className="  md:w-[50%] bg-[#67365E] font-[600] w-full text-white text-[13px]  flex justify-center items-center py-[5px] px-2 rounded-3xl  hover:bg-pink-800 duration-500 cursor-pointer"
                              >
                                No Active Plan
                              </button>
                            </div>
                          </th>
                        </div>
                      )}


                      <p className=" text-[18px] font-[700] mt-2   text-[#AC4884]  mb-2">Your Credit Balance

                      </p>
                      {/* font-[500] text-[13px] */}
                      <p className=" text-[15px] font-[500]  mt-2 text-left text-[#613359]"> You have the following credits in your account which can be utilized for self & partner evaluations. You can choose to pay for your partner assessments yourself and the invited partner does not have to pay. Every time you send an partner invite, your balance will be deducted in case they accept. However, if your prospective partner is already a paid member, only couple match credit will be deducted from your account.</p>
                      <br />
                      {/* <div className="flex items-center"> <p className=" text-[15px] font-[500]font-[600]   text-[13px] text-justify mb-7  ">Please refresh this page to check your updated balance </p> */}


                      {/* </div> */}

                      <div className="flex justify-center">
                        <div className="flex md:w-[60%] my-5 justify-center items-center md:flex-nowrap flex-wrap  border-[#8a4971]  border-solid border-[2px] rounded-xl  ">

                          <table className="w-full ">
                            <tr className="  border-r-[#8a4971] ">
                              <th className="font-[700] border-[#8a4971] border-r-2 border-b-2 w-[80%]  mt-[20px] text-[#AC4884] text-[15px]">
                                KnotRiteType<br />
                                <p className=" text-[15px] font-[500]text-[10px] font-[600]">
                                  (Number of Individual Paid Assessments)
                                </p> <br />
                              </th>


                              <th className=" w-[20%] border-[#8a4971] border-b-2">
                                <div className="flex md:justify-end justify-center items-center pt-2 pb-2 pr-2 w-[100%]">
                                  <button

                                    className="  md:w-[50%] font-[600] w-full text-[#AC4884] text-[13px]  flex justify-around items-center py-[5px] px-2 rounded-3xl  duration-500 cursor-pointer"
                                  >
                                    {user && user.paid_assessment_remaining}
                                  </button>





                                </div>
                              </th>
                            </tr>
                            <tr>  <th className="font-[700] mt-[20px] text-[#AC4884] border-[#8a4971]  border-r-2 text-[15px]">
                              KnotRiteCoupleMatch

                              <br />
                              <p className=" text-[15px] font-[500]text-[10px] font-[600]">
                                ( Number of Couple Compatibility tests)
                              </p> <br />
                            </th>


                              <th className=" w-[100%]  border-[#8a4971] ">
                                <div className="flex md:justify-end justify-center items-center pt-2 pb-2 pr-2 w-[100%]">
                                  <button

                                    className="  md:w-[50%] font-[600] w-full text-[#AC4884] text-[13px]  flex justify-around items-center py-[5px] px-2 rounded-3xl   duration-500 cursor-pointer"
                                  >
                                    {user && user.couple_test_remaining}
                                  </button>





                                </div>
                              </th></tr>
                            {/* <tr>
<th className="font-[700] mt-[20px] text-[#AC4884] border-[#8a4971]   border-r-2 text-[15px]">
  KnotRite Counseling Sessions

  <br />
  <p className=" text-[15px] font-[500]text-[10px] font-[600]">
    (number of hours)
  </p> <br />
</th>


<th className=" w-[100%] border-[#8a4971] ">
  <div className="flex md:justify-end justify-center items-center pt-2 pb-2 pr-2 w-[100%]">
    <button
      onClick={() => Nav("/pricing")}
      className="  md:w-[50%] font-[600] w-full text-[#AC4884] text-[13px]  flex justify-around items-center py-[5px] px-2 rounded-3xl   duration-500 cursor-pointer"
    >
     {subscription && subscription.current_plan?.councel || 0}
    </button>





  </div>
</th>
</tr> */}
                          </table>
                        </div>
                      </div>

                      <div className="flex justify-between items-baseline ">
                        <h1 className="font-[600] text-[13px]  mb-2">
                          If you require additional  evaluations you can buy further plans
                        </h1>
                      </div>
                      <button
                        onClick={() => Nav("/pricing")}
                        className="  md:w-[33%] my-2  bg-[#67365E] font-[600] w-full text-white text-[13px]  flex justify-around items-center py-[5px] px-2 rounded-3xl  hover:bg-pink-800 duration-500 cursor-pointer"
                      >
                        EXPLORE PLANS
                      </button>
                    </div>
                  </div>
                </div>
              </div>


              {deleteModalConf.visible && <div class="flex items-center  z-30 justify-center "><div class="modal opacity-1  fixed w-full  h-full top-0 left-0 flex items-center justify-center">

                <div
                  class="modal-container  max-w-[80%] md-w-fit bg-gradient-to-t from-[#641662] to-pink-200  mx-auto rounded shadow-lg z-50 overflow-y-auto">
                  <div class="modal-content py-2 text-left px-2">

                    {/* <h2 class="text-center font-bold text-2xl mt-1 text-white">Delete Partner?</h2> */}
                    <p class=" text-white font-medium text-center my-6 mx-2 ">
                      Delete at least one existing Partner to add further Partners
                    </p>

                    <div class="flex-row w-full md:flex items-center md:justify-between py-4 md:w-[90%] text-center mx-auto ">
                      <div class="flex justify-around w-full my-4">
                        <button
                          onClick={() => handleDeleteModalConf(null)}
                          class="modal-close px-11 py-2 bg-[#67365E] rounded-full cursor-pointer text-white font-semibold hover:bg-gray-800  hover:text-gray-100 focus:outline-none">
                          OK.</button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>}

              {isRrport && <div class="md:hidden flex items-center  z-30 justify-center "><div class="modal opacity-1  fixed w-full  h-full top-0 left-0 flex items-center justify-center">

                <div
                  class="modal-container  max-w-[80%] md-w-fit bg-gradient-to-t from-[#641662] to-pink-200  mx-auto rounded shadow-lg z-50 overflow-y-auto">
                  <div class="modal-content py-2 text-left px-2">

                    {/* <h2 class="text-center font-bold text-2xl mt-1 text-white">Delete Partner?</h2> */}
                    <p class=" text-white font-medium text-center my-6 mx-2 ">
                      Button not active since partner has not completed test
                    </p>

                    <div class="flex-row w-full md:flex items-center md:justify-between py-4 md:w-[90%] text-center mx-auto ">
                      <div class="flex justify-around w-full my-4">
                        <button
                          onClick={() => setis_Report(false)}
                          class="modal-close px-11 py-2 bg-[#67365E] rounded-full cursor-pointer text-white font-semibold hover:bg-gray-800  hover:text-gray-100 focus:outline-none">
                          OK.</button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>}

              {deleteModal.visible && <div class="flex items-center mx-auto z-30 justify-center "><div class="modal opacity-1 mx-auto fixed md:min-w-[500px]  min-h-[500px] h-full top-0 flex items-center justify-center">

                <div
                  class="modal-container   bg-gradient-to-t from-[#641662] to-pink-200  w-[90%] rounded-[30px]  md:max-w-lg mx-auto  shadow-lg z-50 overflow-y-auto">
                  <div class="modal-content py-4 text-left md:px-6">
                    <div class="flex justify-end items-center pb-3">
                      <div class="modal-close cursor-pointer z-50 p-2" onClick={() => handleDeleteModal(null)}>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400 " fill="none"
                          viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </div>
                    </div>
                    <h2 class="text-center font-bold text-2xl mt-1 text-white">Delete Partner?</h2>
                    <p class=" text-black text-[10px] font-[600] text-justify my-6 mx-4 md:mx-6 ">
                      Are you sure you want to delete Partner? Once deleted, the couple match report is no longer retained by us.. <br /> <br />
                      If you have already done Couplematch test with this partner,you can check your email inbox for the report which was mailed to you.
                    </p>
                    {/* <div
          class="px-4 flex flex-row py-4 min-w-min border-l-4 border-red-400  bg-red-100 rounded mx-auto">
          <span class="w-6 h-6 mr-4 mt-1 text-red-500 mt-2 ">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd"
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                clip-rule="evenodd" />
            </svg>
          </span>
           <div>
            <h2 class="text-lg font-bold text-red-700 ">Warning</h2>
            <p class="text-sm my-2 text-red-500 mt-2 font-medium">By deleting this media 8 connected
              hotspots will also be deleting.</p>
          </div> 
        </div> */}
                    <div class="flex-row w-full md:flex items-center md:justify-between py-4 md:w-[90%] text-center md:mx-auto ">
                      <div class="flex justify-around w-full my-4">
                        <button
                          onClick={() => handleDeleteModal(null)}
                          class="modal-close md:px-4 px-2 py-2 bg-[#67365E] text-[12px] rounded-full cursor-pointer text-white font-semibold hover:bg-gray-800  hover:text-gray-100 focus:outline-none">No,
                          Keep Partner.</button>&nbsp;&nbsp;&nbsp;
                        <button
                          onClick={() => { deletePartner(deleteModal.id) }}
                          class="modal-close md:px-4 px-2 bg-red-500 cursor-pointer text-[12px]  rounded-full text-white font-semibold hover:bg-red-600  hover:text-gray-100  focus:outline-none">
                          Yes, Delete Partner!
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>}

            </section>
          </>}
      {user && <AddPartner visible={visible} gender={user.gender == "male" ? "female" : "male"} onClose={onClose} loadData={loadData} />}
      {loader && <PenndingInvitations prdata={(e) => setpt(e)} is_canAccept={partners.length < 3} visible={Invisible} gender={user.gender == "male" ? "female" : "male"} onClose={onClose} loadData={loadData} />}
      <TestPriceModal visible={Pricingtestvisible} onClose={onPricingTestClose} loadData={loadData} />
      <TestModal visible={testvisible} onClose={onTestClose} loadData={loadData} ></TestModal>
      {
        otpStatus && (
          <VerifyOTPModal
            showModal={showModalOTP}

            data={{
              ...user, dob: () => {

                let final = user.dob
                return final;
              }
            }}
            isAlreadyLogin={true}
            setShowModalOTP={setShowModalOTP}
            setShowModal={setShowModalOTP}
            mobile_number={user.mobile_number}
          />
        )
      }
    </React.Fragment >
  );
}
